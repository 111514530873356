import angular from "angular";
import last from "lodash/last";
import {DateTime} from "luxon";

/* @ngInject */
export default class ItemListController {
  constructor(
    $rootScope,
    $scope,
    $document,
    $window,
    $mdDialog,
    Config,
    ScrollService,
    InboxItemService,
    ActionService,
    SortService,
    PaginationService,
    SearchService,
    SocketService,
    PubsubMessageService,
    InboxItemSearchParamsService
  ) {
    this._$document = $document;
    this._$window = $window;
    this.loading = true;

    this.features = Config.features;
    this._$scope = $scope;
    this._$rootScope = $rootScope;
    this._$mdDialog = $mdDialog;
    this._InboxItemService = InboxItemService;
    this._ActionService = ActionService;
    this._Search = SearchService;
    this._SocketService = SocketService;
    this._PubsubMessageService = PubsubMessageService;
    this._InboxItemSearchParamsService = InboxItemSearchParamsService;
    this._ScrollService = ScrollService;
    this._SortService = SortService;
    this._PaginationService = PaginationService;

    // Bound to component
    this.searchParams = null;
    this.prefilledSearch = null;
    this.listType = null;
    this._replaceItemList([]);
    this._queuedPubsubMessages = [];

    this._Search.subscribe($scope, () => this._searchTextUpdated());

    this.$onInit = this._init;
  }

  /// Public Functions ///

  get items() {
    return this._items;
  }

  get isEmpty() {
    return this.items.length === 0;
  }

  get sortProperty() {
    return this._sortService.orderBy[0].property;
  }

  get sortDirection() {
    return this._sortService.orderBy[0].direction;
  }

  updateSearchParamsOrder() {
    this.searchParams.order = this._sortService.sqlOrder;

    // Add itemNumber as additional sort param for cases when timestamp is equal.
    if (!this.searchParams.order.find(([columnName, _]) => columnName === "itemNumber")) {
      const defaultOrder = this.searchParams.order[this.searchParams.order.length - 1];
      const defaultOrderDirection = defaultOrder[1];
      this.searchParams.order.push(["itemNumber", defaultOrderDirection]);
    }
  }

  clickedSort(column) {
    this._sortService.sortBy(column);
    this.updateSearchParamsOrder();
    return this.goToFirstPage();
  }

  async goToFirstPage() {
    if (this.selectedItem) {
      this.selectedItem = await this._InboxItemService.deselectItem();
    }

    this.paginationService.goToFirstPage();

    this._replaceItemList(await this._getItems(true));
  }

  async goToPreviousPage() {
    if (this.selectedItem) {
      this.selectedItem = await this._InboxItemService.deselectItem();
    }

    this.paginationService.goToPreviousPage();

    this._replaceItemList(await this._getItems(true));
  }

  async goToNextPage() {
    if (this.selectedItem) {
      this.selectedItem = await this._InboxItemService.deselectItem();
    }

    this.paginationService.goToNextPage();

    this._replaceItemList(await this._getItems(true));
  }

  async goToLastPage() {
    if (this.selectedItem) {
      this.selectedItem = await this._InboxItemService.deselectItem();
    }

    this.paginationService.goToLastPage();

    this._replaceItemList(await this._getItems(true));
  }

  showNoResultsMessage() {
    return (
      (!!this.searchErrorMessage || (this.isEmpty && this._isSiblingListEmpty(this.listType))) &&
      this.listType !== "myItems" &&
      this.listType !== "myTriageItems"
    );
  }

  get noResultsMessage() {
    let message = "";

    // The primary list on each page that will control errors (when 2 lists are on the same page)
    const primaryItemLists = ["itemsNotAssignedToMe", "triageItemsNotAssignedToMe", "completedItems"];

    if (this.searchErrorMessage) {
      message = this.searchErrorMessage;
    } else if (
      this._Search.searchText &&
      primaryItemLists.includes(this.listType) &&
      this.isEmpty &&
      this._isSiblingListEmpty(this.listType)
    ) {
      message = "No results";
      if (this._Search.searchText.match(/classification:(none|null|"")/g)) {
        message += ' — Type "is:unclassified" to search for unclassified events';
      }
    } else if (!this._Search.searchText && this.isEmpty && this.listType === "completedItems") {
      message = "There are no completed items at this time";
    } else if (
      !this._Search.searchText &&
      primaryItemLists.includes(this.listType) &&
      this.isEmpty &&
      this._isSiblingListEmpty(this.listType)
    ) {
      message = "There are no items to review at this time";
    }

    return message;
  }

  /// Private Functions ///

  _init() {
    this.defaultSearchParams = angular.copy(this.searchParams);
    this.selectedItem = null;
    this.paginationService = new this._PaginationService(this.searchParams.limit);
    const defaultColumn = this.searchParams.order[0][0];
    const defaultDirection = this.searchParams.order[0][1];
    this._sortService = new this._SortService(defaultColumn, defaultDirection);
    this.updateSearchParamsOrder();

    const pubSubHandler = this._queuePubsubMessage.bind(this);
    this._SocketService.socket.on("message", pubSubHandler);
    this._$scope.$on("$destroy", () => {
      // Remove pubsub listener manually so that navigation's pubsub listeners are not affected
      this._SocketService.socket.removeListener("message", pubSubHandler);
    });

    const deregisterDeselectItem = this._$rootScope.$on("deselect-inbox-item", async () => {
      if (this.selectedItem) {
        const itemToDeselect = this.selectedItem;
        await this._InboxItemService.deselectItem();
        setTimeout(() => {
          this._$rootScope.$emit("inbox-item-deselected", itemToDeselect);
        }, 100);
      }
    });
    this._$scope.$on("$destroy", deregisterDeselectItem);

    const deregisterDeselectionHandler = this._$rootScope.$on(
      "inbox-item-deselected",
      async (emittedEvent, deselectedItem) => {
        if (this.selectedItem) {
          if (this.selectedItem.id === deselectedItem?.id) {
            this.selectedItem = null;
          }
          this._refreshItemList(await this._getItems());
        }
      }
    );
    this._$scope.$on("$destroy", deregisterDeselectionHandler);

    this._getItems()
      .then((items) => this._replaceItemList(items))
      .finally(() => {
        this._processPubsubMessages();

        if (this.prefilledSearch !== "") {
          this._Search.searchText = this.prefilledSearch;
        } else {
          this.loading = false;
        }
      });
  }

  _queuePubsubMessage(jsonMessage) {
    this._queuedPubsubMessages.unshift(jsonMessage);
    if (!this.loading && !this._pubsubProcessing) {
      this._processPubsubMessages();
    }
  }

  async _processPubsubMessages() {
    if (this._queuedPubsubMessages.length > 0) {
      try {
        this._pubsubProcessing = true;
        await this._handlePubsub(this._queuedPubsubMessages.pop());
      } catch (err) {
        console.error(err);
      }

      this._pubsubProcessing = false;
      this._processPubsubMessages();
    }
  }

  _handlePubsub(jsonMessage) {
    const parsedMessage = JSON.parse(jsonMessage);
    const message = this._PubsubMessageService.instantiate(parsedMessage);

    if (!message.isSupported) {
      return Promise.resolve();
    }
    if (message.isInboxItemType) {
      return this._handleItem(message);
    }
    if (message.isStudy) {
      return this._handleStudy(message);
    }
    if (message.isEnrollment) {
      return this._handleEnrollment(message);
    }
    return Promise.resolve();
  }

  _handleEnrollment({messageType, data: enrollment}) {
    const matchesStudy = async (item) => {
      const enrollmentStudyMatchesItem = enrollment.studyId && enrollment.studyId === item.studyId;

      if (enrollmentStudyMatchesItem) {
        item = this._InboxItemService.getItemById(item.id, {
          "tzmedical-br-origin": "list-handle-matches-study",
        });
      }
      return Promise.resolve();
    };

    const matchesEnrollment = (item) => {
      const enrollmentStudyMatchesItem = enrollment.studyId && enrollment.studyId === item.studyId;
      const enrollmentMatchesItemStudy = enrollment.id === item.studyEnrollmentId;
      const enrollmentMatchesItem = enrollment.id === item.enrollmentId;
      const enrollmentInItemArray =
        Array.isArray(item.allEnrollments) && item.allEnrollments.some((e) => enrollment.id === e.id);

      if (
        enrollmentStudyMatchesItem ||
        enrollmentMatchesItemStudy ||
        enrollmentMatchesItem ||
        enrollmentInItemArray
      ) {
        item = this._InboxItemService.getItemById(item.id, {
          "tzmedical-br-origin": "list-handle-matches-enrollment",
        });
      }
      return Promise.resolve();
    };

    const matcher = messageType === "Created" ? matchesStudy : matchesEnrollment;

    return Promise.all(this._items.map(matcher));
  }

  async _handleStudy({data: study}) {
    // This order must be followed: fetch -> record item position -> update state array -> auto scroll
    const newItems = await this._getItems();

    let selectedItemYPosition;
    if (this.selectedItem) {
      selectedItemYPosition = this._ScrollService.getElementTop(this.selectedItem.id);
    }

    this._refreshItemList(newItems);
    await this._scrollToSelectedItem(selectedItemYPosition);

    if (this.selectedItem?.studyId === study.id) {
      this.selectedItem.studyDays = Math.floor(this.selectedItem.configuredDuration / 24);

      if (!["holter", "extendedHolter"].includes(this.selectedItem.studyType)) {
        // Get latest study settings here (not setting when item was recorded) so that the item
        // lists up-to-date study duration and arrhythmia thresholds
        const settings = await this._ActionService.getLatestSettings(
          this.selectedItem.enrollmentId,
          this.selectedItem.studyEndDate
        );
        this.selectedItem.tachyBpm = settings.tachyBpm;
        this.selectedItem.bradyBpm = settings.bradyBpm;
      }
    }
  }

  _handleItem({messageType, data: item}) {
    const itemHasStudy = !!item.studyId;
    const itemIsNotification =
      item.title === "Unconfigured Device On Patient" || item.title === "Study Action Failed";
    if (!itemHasStudy && !itemIsNotification) {
      return Promise.resolve();
    }
    if (this.listType === "myTriageItems" || this.listType === "triageItemsNotAssignedToMe") {
      item.isTriageItem = true;
    }

    item = this._InboxItemService.instantiateItem(item);
    const alreadyInList =
      this._isItemInList(item.id) || (messageType === "Deleted" && item.id === this.selectedItem?.id);
    const matchesSearchText = this._Search.itemMatchesSearchText(item);
    const matchesDefaultParams = this._InboxItemSearchParamsService.itemMatchesDefaultSearchParams(
      item,
      this.defaultSearchParams,
      item.isTriageItem
    );
    const matchesAllSearchParams = matchesSearchText && matchesDefaultParams;

    if (alreadyInList) {
      return this._handleExistingItem(messageType, item, matchesAllSearchParams);
    }

    if (messageType === "Deleted") {
      // If the Message is a Deleted Item and it is not in the list, we don't care about it.
      return Promise.resolve();
    }

    if (matchesAllSearchParams) {
      return this._handleNewItem(item);
    }

    return Promise.resolve();
  }

  async _scrollToSelectedItem(previousYPosition = 0, behavior = undefined, scrollTimeout = 50) {
    if (this.selectedItem) {
      await new Promise((r) => {
        setTimeout(r, scrollTimeout);
      });
      const newYPosition = this._ScrollService.getElementTop(this.selectedItem.id);
      this._ScrollService.scrollByDistance(newYPosition - previousYPosition, behavior);
    }
  }

  _isLastItemSelected(items = this._items) {
    return this.selectedItem && items.length > 0 && last(items).id === this.selectedItem.id;
  }

  _isItemInList(id, items) {
    return this._getItemIndexById(id, items) !== -1;
  }

  _getItemIndexById(id, items = this._items) {
    return items.findIndex((item) => item.id === id);
  }

  /*
   * Note: This handler is only triggered if the item is not sorted between the start and
   * end of the item list
   */
  _handleMessageOnFirstPage(item, indexToInsertAt) {
    const sortedBeforeFirstItem = indexToInsertAt === 0;
    const pageIsFull = this._items.length >= this.paginationService.pageSize;

    if (sortedBeforeFirstItem) {
      this._insertItem(item, 0);
      this._removeExcessItems();
    } else if (!pageIsFull) {
      this._appendItem(item);
      this._$scope.$apply();
    }
  }

  /*
   * Note: This handler is only triggered if the item is not sorted between the start and
   * end of the item list
   */
  _handleMessageOnLastPage(item, indexToInsertAt) {
    const sortedAfterLastItem = indexToInsertAt === -1;
    const pageIsFull = this._items.length >= this.paginationService.pageSize;

    if (sortedAfterLastItem && !pageIsFull) {
      this._appendItem(item);
      this._$scope.$apply();
    }
  }

  async _handleExistingItem(messageType, item, matchesSearch) {
    if (matchesSearch && messageType === "Updated") {
      const itemToUpdate = this._getItemById(item.id);
      await this._InboxItemService.updateItem(item, itemToUpdate);
      setTimeout(() => {
        this._removeExcessItems();
      }, 200);
    } else if (!matchesSearch || messageType === "Deleted") {
      /*
       * IF the item exists in the list AND
       *   - does not match search OR
       *   - message type is "Deleted"
       * THEN We need to remove the item, but doing so would leave the page short an item.
       * In order to keep the page length the same, we have to get an item- but we
       * can't know which item to get, so we have to get all the items for the page again
       * and update the list in place rather than replacing it (to prevent the list disappearing then
       * reappearing)
       */
      const pageWillBeEmpty = this._items.length === 1;
      if (pageWillBeEmpty) {
        this.paginationService.goToPreviousPage();
      }

      // This order must be followed: fetch -> record item position -> update state array -> auto scroll
      const newItems = await this._getItems(pageWillBeEmpty);

      let selectedItemYPosition;
      if (this.selectedItem) {
        selectedItemYPosition = this._ScrollService.getElementTop(this.selectedItem.id);
      }

      this._refreshItemList(newItems);
      await this._scrollToSelectedItem(selectedItemYPosition);
    }
  }

  async _handleNewItem(item) {
    const indexToInsertAt = this._sortService.getIndexToInsertAt(item, this._items);
    const sortedWithinList = indexToInsertAt !== -1 && indexToInsertAt !== 0;
    const pageIsFull = this._items.length >= this.paginationService.pageSize;
    const insertingAtEnd = indexToInsertAt === this._items.length;
    const isLastPage = this.paginationService.isCurrentPageLast;
    const isFirstPage = this.paginationService.isCurrentPageFirst;

    await this._getAndUpdateItemCount();

    let selectedItemYPosition;
    if (this.selectedItem) {
      selectedItemYPosition = this._ScrollService.getElementTop(this.selectedItem.id);
    }

    if (sortedWithinList && (!pageIsFull || !insertingAtEnd)) {
      this._insertItem(item, indexToInsertAt);
      this._removeExcessItems();
    } else if (isFirstPage && !sortedWithinList) {
      this._handleMessageOnFirstPage(item, indexToInsertAt);
    } else if (isLastPage && !sortedWithinList) {
      this._handleMessageOnLastPage(item, indexToInsertAt);
    }

    await this._scrollToSelectedItem(selectedItemYPosition);
  }

  /*
   * Add item at index
   */
  _insertItem(item, index, items = this._items) {
    items.splice(index, 0, item);
  }

  _appendItem(item, items = this._items) {
    items.push(item);
  }

  _removeLastItem(items = this._items) {
    items.pop();
  }

  _removeSecondToLastItem(items = this._items) {
    items.splice(items.length - 2, 1);
  }

  _removeExcessItems(items = this._items) {
    // If there are more items displayed than allowed, pop the last items
    let popItems = true;
    while (popItems) {
      const isLastItemSelected = this._isLastItemSelected(items);
      const pageIsOverFull = items.length > this.paginationService.pageSize;

      if (pageIsOverFull && !isLastItemSelected) {
        this._removeLastItem();
      } else if (isLastItemSelected && items.length > this.paginationService.pageSize + 1) {
        this._removeSecondToLastItem();
      } else {
        popItems = false;
      }
    }

    if (items === this._items) {
      this._$scope.$apply();
    }
  }

  /*
   * Overwrite the existing item list
   */
  _replaceItemList(newItems) {
    if (newItems === null) {
      // Do Nothing
    } else if (Array.isArray(this._items)) {
      this._items.length = 0;
      this._items.push(...newItems);
    } else {
      this._items = newItems;
    }
  }

  /*
   * Given a freshly fetched list of items matching the current search/pagination,
   * sort out any discrepancies without replacing the existing list
   */
  _refreshItemList(newItems) {
    this._replaceItemList(newItems);

    if (this.selectedItem) {
      const selectedItemIndex = this._getItemIndexById(this.selectedItem.id);
      if (selectedItemIndex === -1) {
        this._appendItem(this.selectedItem);
      } else {
        this._items[selectedItemIndex] = Object.assign(this.selectedItem, this._items[selectedItemIndex]);
      }
    }

    this._removeExcessItems();
  }

  _getItemById(id) {
    return this._items.find((item) => item.id === id);
  }

  _getItems(withLoading = false) {
    if (withLoading) {
      this.loading = true;
    }
    const queryParams = this._convertSearchToQueryParams();
    if (!queryParams) {
      return Promise.resolve([]);
    }

    // Provide a way to determine if the request has been overridden by another search
    const itemRequestTimestamp = new Date().getTime();
    this.itemRequestTimestamp = itemRequestTimestamp;

    let requestAborted = false;

    return this._InboxItemService
      .getItems(queryParams, this.listType.toLowerCase().includes("triage"), {
        "tzmedical-br-origin": "list-initialize",
      })
      .then(({items, totalCount}) => {
        // If this request is no longer the most recent request, return null so that the item list is not used
        if (this.itemRequestTimestamp !== itemRequestTimestamp) {
          requestAborted = true;
          return null;
        }

        this.paginationService.totalCount = totalCount;
        this._InboxItemService.listCounts[this.listType] = Number(totalCount);

        const itemsToDisplay = this._addStudyStartDateToDisplay(items);
        return this.listType === "myTriageItems" || this.listType === "triageItemsNotAssignedToMe"
          ? this._convertToTriageItems(itemsToDisplay)
          : itemsToDisplay;
      })
      .catch((err) => {
        // Ignore 500 errors so that valid frontend data is not thrown out
        if (err.status >= 500 && this._items.length > 0) {
          return [...this._items];
        }

        if (err?.data?.detail?.message) {
          this.searchErrorMessage = err.data.detail.message;
        } else {
          this.searchErrorMessage = "An error occurred while loading items for this page";
        }
        console.error(err);
        return [];
      })
      .finally(() => {
        if (!requestAborted && withLoading) {
          this.loading = false;
          angular.element(() => {
            this._$scope.$apply();
          });
        }
      });
  }

  _addStudyStartDateToDisplay(items) {
    return items.map((item) => {
      // Valid timestamps are 2010 or later
      item.validStudyStartDate = DateTime.fromISO(item.studyStartDate).year >= 2010;

      if (item.validStudyStartDate) {
        item.displayedStudyStartDate = DateTime.fromJSDate(new Date(item.studyStartDate)).toFormat(
          "yyyy-MM-dd HH:mm"
        );
      } else {
        item.displayedStudyStartDate = "Unknown Timestamp";
      }

      return item;
    });
  }

  _convertToTriageItems(items) {
    return items.map((item) => {
      item.isTriageItem = true;
      return item;
    });
  }

  _getAndUpdateItemCount() {
    const queryParams = this._convertSearchToQueryParams();
    if (!queryParams) {
      return Promise.resolve();
    }
    const queryParamsLimitZero = {...queryParams};
    queryParamsLimitZero.limit = 0;
    return this._InboxItemService
      .getItems(queryParamsLimitZero, this.listType.toLowerCase().includes("triage"), {
        "tzmedical-br-origin": "list-update-count",
      })
      .then(({totalCount}) => {
        this.paginationService.totalCount = totalCount;
        this._InboxItemService.listCounts[this.listType] = totalCount;

        return totalCount;
      });
  }

  _convertSearchToQueryParams() {
    try {
      // reset the search so that the query params are not duplicated
      this._resetSearch();
      return this._Search.convertSearchToQueryParams(this.searchParams);
    } catch (error) {
      this.searchErrorMessage = error.message;
      this._items = [];
      this.paginationService.totalCount = 0;
      return undefined;
    }
  }

  _resetSearch() {
    this.searchParams = angular.copy(this.defaultSearchParams);

    this.updateSearchParamsOrder();
    this.searchParams.offset = this.paginationService.offset;
  }

  _displayErrorMessage(title, defaultMessage, error) {
    // Format error message
    let errorMessage = defaultMessage;
    if (error?.data?.detail?.message) {
      errorMessage = error.data.detail.message.replace(/\n/g, "<br />");
    }
    // display error dialog
    return this._$mdDialog.show(
      this._$mdDialog
        .alert()
        .title(title)
        .htmlContent(
          `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
            `${errorMessage}</p>`
        )
        .ok("Ok")
    );
  }

  _searchTextUpdated() {
    // This is a debouncer to reduce API calls from the search bar while typing
    clearTimeout(this.cachedTimeout);
    this.cachedTimeout = setTimeout(() => {
      this.searchErrorMessage = null;

      this.goToFirstPage().finally(() => {
        this._$scope.$apply();
      });
    }, 1000);
  }

  _getDeviceOrPatientHeader() {
    return this.listType === "myTriageItems" || this.listType === "triageItemsNotAssignedToMe"
      ? "Device"
      : "Patient";
  }

  _getDeviceOrPatientSort() {
    return this.listType === "myTriageItems" || this.listType === "triageItemsNotAssignedToMe"
      ? "tzSerial"
      : "patientName";
  }

  _isSiblingListEmpty(listType) {
    let result;
    switch (listType) {
      case "myItems":
        result = this._InboxItemService.listCounts.itemsNotAssignedToMe === 0;
        break;
      case "itemsNotAssignedToMe":
        result = !this.features.itemAssignments || this._InboxItemService.listCounts.myItems === 0;
        break;
      case "myTriageItems":
        result = this._InboxItemService.listCounts.triageItemsNotAssignedToMe === 0;
        break;
      case "triageItemsNotAssignedToMe":
        result = !this.features.itemAssignments || this._InboxItemService.listCounts.myTriageItems === 0;
        break;
      case "completedItems":
      default:
        result = true;
    }
    return result;
  }
}
